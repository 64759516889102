import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { v4 } from 'uuid'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import { Box, Flex, Button, Stack, Heading, useTheme, SimpleGrid } from '@chakra-ui/react'
import { IoIosArrowDropdown } from 'react-icons/io'

import {
  Article,
  Section,
  Banner,
  Hero,
  HeroBody,
  HeroSubtitle,
  HeroCta,
  RowContent,
  ColImage,
  ColInfo,
  P,
  FeaturedBanner,
  Carousel,
  Gallery,
  ColContent,
  Image,
  FeatureList,
  FeatureItem,
  HeroTitle,
  H2,
} from '../ui'

import Layout from '../components/layout'
import Features from '../components/features'
import FormContact from '../components/formQuote'
import ButtonBook from '../components/buttonBook'
import Rooms from '../components/rooms'

import logo from '../images/logo.png'
import marksLeft from '../images/index/wine-marks-left.png'
import marksRight from '../images/index/wine-marks-right.png'

function IndexPage({ data }) {
  const { t } = useTranslation()
  const theme = useTheme()

  const [bookSession, setBookSession] = useState('')

  console.log(data)

  return (
    <Layout title="Home">
      <Hero
        minHeight="100vh"
        image={<Image fluid={data.hero.childImageSharp.fluid} layout="background" />}
      >
        <HeroBody align="center" marginTop="14rem">
          <HeroTitle
            fontSize={{ base: '2.3rem', md: '2.8rem', lg: '3.3rem', xl: '3.8rem' }}
            textAlign="center"
            fontFamily="Futura"
          >
            SPORTING & CHILL
          </HeroTitle>
          <HeroCta align="center">
            <ButtonBook size="lg" marginTop={4} to={t('common:nav.reservations.to')}>
              {t('common:nav.reservations.text')}
            </ButtonBook>
            <Box as="a" href="#about" position="absolute" color="white" bottom="50px">
              <IoIosArrowDropdown color="white" size="3rem" />
            </Box>
          </HeroCta>
        </HeroBody>
      </Hero>

      <Article>
        <Section
          id="about"
          fullWidth
          // background={{
          //   base: '#f6f6f6',
          //   md: `url(${marksRight}) right bottom no-repeat, url(${marksLeft}) left top no-repeat, #f6f6f6`,
          // }}
          backgroundColor="#000000"
          height={{ base: '400px', lg: '500px' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            maxWidth="1024px"
            paddingX={{ base: 6, md: 10, xl: 20 }}
            spacing={8}
            height="100%"
            justify="center"
          >
            <Flex height={{ base: '50px', md: '120px' }} justifyContent="center">
              <img style={{ height: '100%', width: 'auto' }} src={logo} />
            </Flex>
            <P fontWeight="black" color="white">
              En Emblema Sports Bar somos más que un lugar para ver deportes; somos tu destino
              definitivo para el día del partido. Tanto si eres un fan incondicional o simplemente
              buscas pasar un buen rato con amigos, nuestro bar deportivo es el lugar perfecto para
              ver toda la acción. Disfrute de un menú lleno de opciones que le harán la boca agua,
              incluyendo comida clásica de bar, cócteles de autor y una amplia selección de cervezas
              de barril.
            </P>
            <P fontWeight="black" color="white">
              Nuestros chefs preparan especiales para el día del partido que le harán volver a por
              más. No somos sólo para adultos. Traiga a toda la familia para pasar un día de
              diversión y juegos. Ofrecemos un menú para niños y actividades para que todos estén
              entretenidos.
            </P>
          </Stack>
        </Section>

        <Section id="food-and-drink" fullWidth paddingTop={0} paddingBottom={0}>
          <RowContent
            minHeight={{ lg: '600px', xl: '700px' }}
            gridTemplateColumns={{ lg: '1fr 1fr' }}
          >
            <ColImage
              image={<Image fixed={data.foodDrink.childImageSharp.fixed} layout="background" />}
            />
            <ColInfo title="MENU" titleAlign="center">
              <Button
                as="a"
                href="https://drive.google.com/file/d/17M1Z17mfuqkcDkSXJI7W2rgTv1mNCZVD/view"
                alignSelf="center"
                size="lg"
                marginTop={4}
                color="white"
                width="fit-content"
                background="#e50040"
                _hover={{ background: '#e50040' }}
              >
                {t('index:menu.button')}
              </Button>
            </ColInfo>
          </RowContent>
        </Section>

        <Section fullWidth paddingTop={0} paddingBottom={0}>
          <FeaturedBanner
            image={<Image fluid={data.banner.childImageSharp.fluid} layout="background" />}
          >
            <H2
              color="white"
              fontSize={{ base: '1.6rem', md: '2.1rem', lg: '2.6rem' }}
              textAlign="center"
            >
              SPORTS BAR
            </H2>
          </FeaturedBanner>
        </Section>

        <Section title="Promociones & Eventos" titleAlign="center">
          {/* <Image fixed={image.node.childImageSharp.fixed} layout="background" /> */}
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
            <Box
              as="a"
              href={`https://api.whatsapp.com/send?phone=+5216862614811&text=Hola, quiero reservar en Emblema Sports Bar...`}
            >
              <Image fluid={data.tulum.childImageSharp.fluid} layout="fluid" />
            </Box>
            <Box
              as="a"
              href={`https://api.whatsapp.com/send?phone=+5216862614811&text=Hola, quiero reservar en Emblema Sports Bar Hoy Domingo...`}
            >
              <Image fluid={data.anniversary.childImageSharp.fluid} layout="fluid" />
            </Box>
            <Box
              as="a"
              href={`https://api.whatsapp.com/send?phone=+5216862614811&text=Hola, quiero reservar en Emblema Sports Bar Hoy Jueves...`}
            >
              <Image fluid={data.everyday.childImageSharp.fluid} layout="fluid" />
            </Box>
          </SimpleGrid>
        </Section>

        <Section
          id="gallery"
          title={t('index:gallery.title')}
          titleAlign="center"
          backgroundColor="black"
          color="white"
          fullWidth
        >
          <Box paddingX={{ base: 6, md: 10, xl: 20 }}>
            <Gallery
              color="initial"
              paddingBottom="0"
              marginBottom="0"
              images={data.gallery.edges.map((image) => (
                <Image key={v4()} fixed={image.node.childImageSharp.fixed} layout="background" />
              ))}
              buttonText={t('common:viewPhotos')}
            />
          </Box>
        </Section>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "index/banner-home1.jpg" }) {
      ...bannerSectionImage
    }

    banner: file(relativePath: { eq: "index/banner-img2.jpg" }) {
      ...bannerSectionImage
    }

    foodDrink: file(relativePath: { eq: "index/img-home1.jpg" }) {
      ...fixedImage
    }

    beerFest: file(relativePath: { eq: "promo/beer-fest.jpeg" }) {
      ...fluidImage
    }

    everyday: file(relativePath: { eq: "promo/everyday.jpeg" }) {
      ...fluidImage
    }

    tulum: file(relativePath: { eq: "promo/i-love-tulum-es.jpeg" }) {
      ...fluidImage
    }

    music: file(relativePath: { eq: "promo/live-music.jpeg" }) {
      ...fluidImage
    }

    margaritas: file(relativePath: { eq: "promo/margaritas.jpeg" }) {
      ...fluidImage
    }

    smoothies: file(relativePath: { eq: "promo/smoothies.jpeg" }) {
      ...fluidImage
    }

    sushi: file(relativePath: { eq: "promo/sushi.jpeg" }) {
      ...fluidImage
    }

    tequila: file(relativePath: { eq: "promo/tequila.jpeg" }) {
      ...fluidImage
    }

    anniversary: file(relativePath: { eq: "promo/emblema-anniversary.png" }) {
      ...fluidImage
    }

    gallery: allFile(
      filter: { relativeDirectory: { eq: "index/gallery" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }
  }
`

export default IndexPage
