import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'

import { Image, RoomItem, RoomList } from '../ui'

function Rooms(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          garden: allFile(
            filter: { relativeDirectory: { eq: "rooms/garden" } }
            sort: { fields: name }
            limit: 3
          ) {
            ...multipleFixedImage
          }

          kids: allFile(
            filter: { relativeDirectory: { eq: "rooms/kids" } }
            sort: { fields: name }
            limit: 3
          ) {
            ...multipleFixedImage
          }

          patron: allFile(
            filter: { relativeDirectory: { eq: "rooms/patron" } }
            sort: { fields: name }
            limit: 3
          ) {
            ...multipleFixedImage
          }

          machine: allFile(
            filter: { relativeDirectory: { eq: "rooms/machine" } }
            sort: { fields: name }
            limit: 3
          ) {
            ...multipleFixedImage
          }
        }
      `}
      render={(data) => (
        <RoomList columns={{ base: 1, md: 2, xl: 4 }} {...props}>
          <RoomItem
            to="/rooms/garden"
            as={Link}
            images={data.garden.edges.map((image) => (
              <Image fixed={image.node.childImageSharp.fixed} layout="background" />
            ))}
            title="Habitación del Huerto"
            desc="Ideal para parejas por su privacidad, se encuentra en la zona Norte de la hacienda, la naturaleza y el canto de las aves serán tus compañeros al despertar. Amplia y luminosa habitación con vistas a los jardines que la rodean."
          />
          <RoomItem
            to="/rooms/kids"
            as={Link}
            images={data.kids.edges.map((image) => (
              <Image fixed={image.node.childImageSharp.fixed} layout="background" />
            ))}
            title="Habitación del los Niños"
            desc="Ideal para familia o grupos, cuenta con Doble Cama Matrimonial. Al igual que la habitación del Patrón, esta habitación se encuentra en el casco principal de la hacienda."
          />
          <RoomItem
            to="/rooms/patron"
            as={Link}
            images={data.patron.edges.map((image) => (
              <Image fixed={image.node.childImageSharp.fixed} layout="background" />
            ))}
            title="Habitación del Patrón"
            desc="Es la habitación estelar, se encuentra dentro de la construcción principal del casco de la hacienda. Majestuosa en sus dimensiones, decoración original de la época henequenera, vistas al jardín y a la increíble terraza principal del casco."
          />
          <RoomItem
            to="/rooms/machine"
            as={Link}
            images={data.machine.edges.map((image) => (
              <Image fixed={image.node.childImageSharp.fixed} layout="background" />
            ))}
            title="Habitación de la Máquina"
            desc="Encontrarás belleza y tradición en todos los sentidos, amplias dimensiones, rodeada de exuberante vegetación y jardines originales. A tan sólo unos pasos del casco principal de la hacienda, dentro de los galerones de la antigua máquina que procesaba el henequén, de allí su nombre."
          />
        </RoomList>
      )}
    />
  )
}

export default Rooms
